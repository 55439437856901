import crypt from 'crypt';
import { utf8 } from 'charenc';
import isBuffer from 'is-buffer';
import { bin } from 'charenc';

// The core
var md5 = function (message, options) {
  // Convert to byte array
  if (message.constructor == String)
    if (options && options.encoding === 'binary') message = bin.stringToBytes(message);
    else message = utf8.stringToBytes(message);
  else if (isBuffer(message)) message = Array.prototype.slice.call(message, 0);
  else if (!Array.isArray(message) && message.constructor !== Uint8Array)
    message = message.toString();
  // else, assume byte array already

  var m = crypt.bytesToWords(message),
    l = message.length * 8,
    a = 1732584193,
    b = -271733879,
    c = -1732584194,
    d = 271733878;

  // Swap endian
  for (var i = 0; i < m.length; i++) {
    m[i] =
      (((m[i] << 8) | (m[i] >>> 24)) & 0x00ff00ff) | (((m[i] << 24) | (m[i] >>> 8)) & 0xff00ff00);
  }

  // Padding
  m[l >>> 5] |= 0x80 << l % 32;
  m[(((l + 64) >>> 9) << 4) + 14] = l;

  // Method shortcuts
  var FF = md5._ff,
    GG = md5._gg,
    HH = md5._hh,
    II = md5._ii;

  for (var i = 0; i < m.length; i += 16) {
    var aa = a,
      bb = b,
      cc = c,
      dd = d;

    a = FF(a, b, c, d, m[i + 0], 7, -680876936);
    d = FF(d, a, b, c, m[i + 1], 12, -389564586);
    c = FF(c, d, a, b, m[i + 2], 17, 606105819);
    b = FF(b, c, d, a, m[i + 3], 22, -1044525330);
    a = FF(a, b, c, d, m[i + 4], 7, -176418897);
    d = FF(d, a, b, c, m[i + 5], 12, 1200080426);
    c = FF(c, d, a, b, m[i + 6], 17, -1473231341);
    b = FF(b, c, d, a, m[i + 7], 22, -45705983);
    a = FF(a, b, c, d, m[i + 8], 7, 1770035416);
    d = FF(d, a, b, c, m[i + 9], 12, -1958414417);
    c = FF(c, d, a, b, m[i + 10], 17, -42063);
    b = FF(b, c, d, a, m[i + 11], 22, -1990404162);
    a = FF(a, b, c, d, m[i + 12], 7, 1804603682);
    d = FF(d, a, b, c, m[i + 13], 12, -40341101);
    c = FF(c, d, a, b, m[i + 14], 17, -1502002290);
    b = FF(b, c, d, a, m[i + 15], 22, 1236535329);

    a = GG(a, b, c, d, m[i + 1], 5, -165796510);
    d = GG(d, a, b, c, m[i + 6], 9, -1069501632);
    c = GG(c, d, a, b, m[i + 11], 14, 643717713);
    b = GG(b, c, d, a, m[i + 0], 20, -373897302);
    a = GG(a, b, c, d, m[i + 5], 5, -701558691);
    d = GG(d, a, b, c, m[i + 10], 9, 38016083);
    c = GG(c, d, a, b, m[i + 15], 14, -660478335);
    b = GG(b, c, d, a, m[i + 4], 20, -405537848);
    a = GG(a, b, c, d, m[i + 9], 5, 568446438);
    d = GG(d, a, b, c, m[i + 14], 9, -1019803690);
    c = GG(c, d, a, b, m[i + 3], 14, -187363961);
    b = GG(b, c, d, a, m[i + 8], 20, 1163531501);
    a = GG(a, b, c, d, m[i + 13], 5, -1444681467);
    d = GG(d, a, b, c, m[i + 2], 9, -51403784);
    c = GG(c, d, a, b, m[i + 7], 14, 1735328473);
    b = GG(b, c, d, a, m[i + 12], 20, -1926607734);

    a = HH(a, b, c, d, m[i + 5], 4, -378558);
    d = HH(d, a, b, c, m[i + 8], 11, -2022574463);
    c = HH(c, d, a, b, m[i + 11], 16, 1839030562);
    b = HH(b, c, d, a, m[i + 14], 23, -35309556);
    a = HH(a, b, c, d, m[i + 1], 4, -1530992060);
    d = HH(d, a, b, c, m[i + 4], 11, 1272893353);
    c = HH(c, d, a, b, m[i + 7], 16, -155497632);
    b = HH(b, c, d, a, m[i + 10], 23, -1094730640);
    a = HH(a, b, c, d, m[i + 13], 4, 681279174);
    d = HH(d, a, b, c, m[i + 0], 11, -358537222);
    c = HH(c, d, a, b, m[i + 3], 16, -722521979);
    b = HH(b, c, d, a, m[i + 6], 23, 76029189);
    a = HH(a, b, c, d, m[i + 9], 4, -640364487);
    d = HH(d, a, b, c, m[i + 12], 11, -421815835);
    c = HH(c, d, a, b, m[i + 15], 16, 530742520);
    b = HH(b, c, d, a, m[i + 2], 23, -995338651);

    a = II(a, b, c, d, m[i + 0], 6, -198630844);
    d = II(d, a, b, c, m[i + 7], 10, 1126891415);
    c = II(c, d, a, b, m[i + 14], 15, -1416354905);
    b = II(b, c, d, a, m[i + 5], 21, -57434055);
    a = II(a, b, c, d, m[i + 12], 6, 1700485571);
    d = II(d, a, b, c, m[i + 3], 10, -1894986606);
    c = II(c, d, a, b, m[i + 10], 15, -1051523);
    b = II(b, c, d, a, m[i + 1], 21, -2054922799);
    a = II(a, b, c, d, m[i + 8], 6, 1873313359);
    d = II(d, a, b, c, m[i + 15], 10, -30611744);
    c = II(c, d, a, b, m[i + 6], 15, -1560198380);
    b = II(b, c, d, a, m[i + 13], 21, 1309151649);
    a = II(a, b, c, d, m[i + 4], 6, -145523070);
    d = II(d, a, b, c, m[i + 11], 10, -1120210379);
    c = II(c, d, a, b, m[i + 2], 15, 718787259);
    b = II(b, c, d, a, m[i + 9], 21, -343485551);

    a = (a + aa) >>> 0;
    b = (b + bb) >>> 0;
    c = (c + cc) >>> 0;
    d = (d + dd) >>> 0;
  }

  return crypt.endian([a, b, c, d]);
};

// Auxiliary functions
md5._ff = function (a, b, c, d, x, s, t) {
  var n = a + ((b & c) | (~b & d)) + (x >>> 0) + t;
  return ((n << s) | (n >>> (32 - s))) + b;
};
md5._gg = function (a, b, c, d, x, s, t) {
  var n = a + ((b & d) | (c & ~d)) + (x >>> 0) + t;
  return ((n << s) | (n >>> (32 - s))) + b;
};
md5._hh = function (a, b, c, d, x, s, t) {
  var n = a + (b ^ c ^ d) + (x >>> 0) + t;
  return ((n << s) | (n >>> (32 - s))) + b;
};
md5._ii = function (a, b, c, d, x, s, t) {
  var n = a + (c ^ (b | ~d)) + (x >>> 0) + t;
  return ((n << s) | (n >>> (32 - s))) + b;
};

// Package private blocksize
md5._blocksize = 16;
md5._digestsize = 16;

function md5Fn(message, options) {
  if (message === undefined || message === null) throw new Error('Illegal argument ' + message);

  var digestbytes = crypt.wordsToBytes(md5(message, options));
  return options && options.asBytes
    ? digestbytes
    : options && options.asString
    ? bin.bytesToString(digestbytes)
    : crypt.bytesToHex(digestbytes);
}

export default md5Fn;
